<template>
	<b-modal ref="modalQualification" @hidden="resetModal" @shown="modalShown">
		<template slot="modal-header" slot-scope="{ close }">
			<h2 class="mx-auto my-auto">{{ $t('qualification.ajouter') }}</h2>
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
		</template>
		<template slot="default">
			<div class="row">
				<div class="col-12" v-if="form_message !== ''">
				    <ErrorAlert :messageI18n="form_message" />
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<label for="qualification">{{ $t('qualification.categorie_parent') }}</label>
					<e-select
						v-model="parent"
						id="qualification"
						track-by="qualification_libelle"
						label="qualification_libelle"
						:placeholder="$t('qualification.rechercher_categorie')"
						:selectedLabel="$t('global.selected_label')"
						:options="qualification_formatted"
						:searchable="true"
						:show-labels="false"
						:allow-empty="true"
						:sortable="false"
						class="form-group"
					>
						<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.qualification_libelle }}</span></template>
					</e-select>
				</div>

				<div class="col-12">
					<div class="form-group">
						<label for="libelle">{{ $t('qualification.categorie_nom') }}</label>
						<input
							id="libelle"
							type="text"
							class="form-control"
							:placeholder="$t('qualification.categorie_nom')"
							v-model="libelle"
						>
					</div>
				</div>

				<div class="col-12">
					<label for="qualification">{{ $t('qualification.questions') }}</label>
					<draggable v-model="questions">
				        <div 
				            v-for="question in questions"
				            :key="`question-${question.question_id}`"
				            class="acte_element mb-3 draggable"
				            :class="{active: question.isActive}"
				            draggable="true"
				            @dragend="orderQuestions($event,false)"
						>

							<div class="row align-items-end mb-2" @click="focusQuestion(question)">
				    			<div class="col acte_titre">
				            		<label>{{ $t('acte_type.titre_question') }}</label>
									<input v-if="question.isActive" type="text" class="form-control" :placeholder="$t('acte_type.titre_question_placeholder')" v-model="question_libelle" :ref="'inputQuestionLabel'+question.question_index">
				            		<input v-else type="text" class="form-control" :placeholder="$t('acte_type.titre_question_placeholder')" :value="question.question_libelle">
				    			</div>
				    			<div class="col acte_type">
				            		<label>{{ $t('acte_type.type_question') }}</label><br/>
				            		<b-dropdown
										no-caret
										toggle-class="text-left custom-select"
										dropdown-class="w-100" class="w-100"
									>
				            			<template slot="button-content" >
											{{ dropdownText }}
										</template>

				                        <b-dropdown-item v-for="input_type in input_types" :key="input_type.typeinput_libelle" @click="selectInputType(input_type)">
				                            <font-awesome-icon :icon="input_type.typeinput_icon" />
				                            {{ $t('question.'+input_type.typeinput_libelle) }}
				                        </b-dropdown-item>

									</b-dropdown>
				    			</div>
				    			<div class="col-auto acte_delete">
				    				<button @click="deleteQuestion(question)" class="btn btn-secondary"><font-awesome-icon :icon="['fal', 'trash-alt']" /></button>
				    			</div>

				    		</div>

							<ManageValues
								v-if="selected_input_type && question.isActive"
								:values.sync="question_valeurs"
								:icon="faIconValues"
								:type="selected_input_type.typeinput_code"
							/>
							<ViewValues
								v-show="!question.isActive"
								:values="question.question_questiondata"
								:icon="question.question_icon"
								:type="question.question_questiontype"
							/>
				    	</div>
					</draggable>
					<div class="text-center">
						<button class="btn btn-secondary rounded-pill mr-3" @click="addQuestion">
							<font-awesome-icon :icon="['fal', 'plus']" /> {{ $t('qualification.add_question') }}
						</button>
					</div>
				</div>
			</div>

		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="saveQualifForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.enregistrer') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
    import Question from '@/mixins/Question.js'	
    import Qualification from '@/mixins/Qualification.js'	
    import _isEmpty from 'lodash/isEmpty'

	export default {
		name: 'ModalQualif',
		props: ['qualification', 'type', 'refresh_table'],
		mixins: [Question, Qualification],
		data () {
			return {
				processing: false,
				form_message: '',
				parent: {},
				libelle: '',
				qualifications: [],

				input_types: [],
				questions: [],
				question_index: 1,
				question_libelle: '', 
				selected_input_type: null,
				question_valeurs: [],
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(!_isEmpty(this.qualification)) {
					this.libelle = this.qualification.qualification_libelle
					let parent = await this.getQualificationById(this.qualification.qualification_parent)
					this.parent = parent[0]
					this.questions= await this.getQuestionQualification(this.qualification.qualification_id)
					if(this.questions.length > 0){
						const last_question = this.questions[this.questions.length-1]
						last_question.isActive = true
						this.autocompleteForm(last_question)
					}
				}

				const input_types = await this.loadTypesInput()
				this.input_types = input_types.filter(input => input.typeinput_code !== "WBS")

				this.qualifications = await this.getQualifications(this.type.qualificationtype_id)

				if(!_isEmpty(this.qualification)) {
					this.qualifications = this.qualifications.filter(qualif => qualif.qualification_id !== this.qualification.qualification_id )
				}
			},

			openModal() {
				this.$refs.modalQualification.show()
			},

			orderQuestions() {
				for (var key in this.questions) {
					this.questions[key].question_index = key
				}
				this.questions.sort(function(a, b){return a.question_index-b.question_index})
			},

			async focusQuestion(nouvelle_question, verification_question_precedente=true) {
				if(nouvelle_question && nouvelle_question.isActive) return

				if(verification_question_precedente && !this.checkQuestionSubmit()) {
					this.failureToast('toast.info_donnees_manquantes')
					return
				}

				this.questions.forEach(question => {
						if(question.isActive){
							question.isActive = false
							this.saveQuestion(question)
						}
					},this
				)

				nouvelle_question.isActive = true
				this.autocompleteForm(nouvelle_question)

				const ref = 'inputQuestionLabel'+nouvelle_question.question_index
				await Common.waitUntil(() => (this.$refs[ref]))
				this.$refs[ref][0].focus()
			},

			checkQuestionSubmit() {
				if(this.questions.length==0) return true
				const libelle_ok = this.question_libelle ? true : false
				const input_type_ok = this.selected_input_type ? true : false
				const valeurs_ok = this.question_valeurs!=null && this.question_valeurs.length > 0 ? true : false
				return libelle_ok && input_type_ok && valeurs_ok
			},

			addQuestion() {
				if(this.questions.length > 0 && !this.checkQuestionSubmit()) {
					this.failureToast('toast.question.info_donnees_manquantes')
					return
				}

				let tmp = {}
				tmp.question_libelle = ''
				tmp.question_placeholder = ''
				tmp.question_index = this.question_index
				tmp.question_questiontype = ''
				tmp.question_questiondata = {}
				tmp.question_icon = ''
				tmp.isActive = false

				this.questions.push(tmp)
				this.orderQuestions()
				this.focusQuestion(tmp, false)
			},

			deleteQuestion(question) {
				this.questions.splice(this.questions.indexOf(question), 1)
				this.orderQuestions()
				if(this.questions.length>0) this.focusQuestion(this.questions[this.questions.length-1],false)
			},

			saveQuestion(question) {
				const input_code = this.selected_input_type.typeinput_code
				const type_data = (input_code == this.type_code_webservice ? 'url' : 'data')

				question.question_libelle = this.question_libelle
				question.question_questiontype = this.selected_input_type.typeinput_code
				question.question_questiondata = { type: type_data, 'data': this.question_valeurs }
				question.question_icon = this.faIconValues
			},

			autocompleteForm(question) {
				this.question_libelle = question.question_libelle
				this.question_valeurs = question.question_questiondata.data
				const selected_input_type = this.input_types.filter(type => type.typeinput_code == question.question_questiontype)
				if(selected_input_type.length > 0) {
					this.selected_input_type = selected_input_type[0]
				}
				this.question_index = question.question_index
			},

			selectInputType(item) {
				this.selected_input_type = item
			},

			async saveQualifForm() {
				if(!this.checkQualifForm()) {
					this.form_message = "formulaire.erreur_champs_non_remplis"
					return
				}

				const last_question = this.questions.filter(question => question.isActive == true)
				if(last_question.length > 0) {
					this.saveQuestion(last_question[0])
				}

				try {
					this.isProcessing = true
					let qualification_id = 0

					this.parent = _isEmpty(this.parent) ? {qualification_id: 0} : this.parent

					if(!_isEmpty(this.qualification)) {
						qualification_id = this.qualification.qualification_id
						await this.updateQualification(qualification_id, this.parent.qualification_id, this.libelle)
					} else {
						qualification_id = await this.createQualification(this.type.qualificationtype_id, this.parent.qualification_id, this.libelle)
					}

					// Si tout va bien, on sauvegarde les éventuelles questions en bdd
					const questions_filled = this.prepareQuestionsTab(this.questions)
					await this.saveQualificationQuestions(qualification_id, questions_filled)

					this.successToast('toast.info_save_succes')
					this.resetModal()

					this.$emit('update:refresh_table', !this.refresh_table)
					this.$refs.modalQualification.hide()
				}
				catch (e) {
					console.error(e)
					this.failureToast('toast.info_save_failed')
				}
			},

			checkQualifForm() {
				return this.libelle && this.checkQuestionSubmit()
			},

			resetModal() {
                this.processing = false
				this.form_message = ''
				this.parent = {}
				this.libelle = ''
				this.qualifications = []
				this.input_types = []
				this.questions = []
				this.question_index = 1
				this.question_libelle = ''
				this.selected_input_type = null
				this.question_valeurs = []
            },

            modalShown() {
            	this.init_component()
            }
		},

		computed:{
			qualification_formatted: function() {
				return this.qualifications
			},
			dropdownText() {
				if(this.selected_input_type) {
					return this.getTrad('question.'+this.selected_input_type.typeinput_libelle)
				}
				return this.getTrad('global.selectionnez_element')
			},
			faIconValues() {
				if(this.selected_input_type) {
					return this.selected_input_type.typeinput_icon
				}
				return ['fal', 'times']
			},
		},

		watch:{
			qualification (val){
				this.init_component()
			}
		},

		components: {
			draggable: () => import('vuedraggable'),
            ManageValues: () => import('@/components/Questions/ManageValues'),
            ViewValues: () => import('@/components/Questions/ViewValues'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
		}
	}

</script>